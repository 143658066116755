<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569ZM12.5962 12.5962C13.8152 11.3772 14.5 9.72391 14.5 8C14.5 6.27609 13.8152 4.62279 12.5962 3.40381C11.3772 2.18482 9.72391 1.5 8 1.5C6.27609 1.5 4.62279 2.18482 3.40381 3.40381C2.18482 4.62279 1.5 6.27609 1.5 8C1.5 9.72391 2.18482 11.3772 3.40381 12.5962C4.62279 13.8152 6.27609 14.5 8 14.5C9.72391 14.5 11.3772 13.8152 12.5962 12.5962Z" fill="#F5FAFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.94273 8.00009L10.8047 6.13813C11.0653 5.87744 11.0653 5.45613 10.8047 5.19548C10.544 4.93484 10.1227 4.93484 9.86204 5.19548L8.00009 7.05744L6.13813 5.19548C5.87744 4.93484 5.45613 4.93484 5.19548 5.19548C4.93484 5.45613 4.93484 5.87744 5.19548 6.13813L7.05744 8.00009L5.19548 9.86204C4.93484 10.1227 4.93484 10.544 5.19548 10.8047C5.32548 10.9347 5.49614 11 5.66681 11C5.83748 11 6.0081 10.9347 6.13813 10.8047L8.00009 8.94273L9.86204 10.8047C9.99208 10.9347 10.1627 11 10.3334 11C10.504 11 10.6747 10.9347 10.8047 10.8047C11.0653 10.544 11.0653 10.1227 10.8047 9.86204L8.94273 8.00009Z" fill="#F5FAFF"/>
  </svg>
</template>

<script>
export default {
  name: "IconDeleteChip"
}
</script>

<style scoped>

</style>